import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Product from '../../components/product';
import Category from '../../components/category';
import BigSearchBar from '../../components/bigSearchBar';
import colors from '../../config/color';

class Homepage extends Component {
  componentDidMount() {
    try {
      for (
        let a = 0;
        a < document.getElementsByClassName('adsbygoogle').length;
        a++
      ) {
        (adsbygoogle = window.adsbygoogle || []).push({});
      }
    } catch (error) {}
  }
  render() {
    const data = this.props.data;
    return (
      <Layout showHeader={false} showHero={false}>
        <SEO title="Here Hear 聽聽" />
        <div className="search-bar-up">
          <Product title={''} edges={data.allWordpressPost.edges} />
          <div className="more-btn-content">
            <Link style={{ textAlign: 'center' }} to={'/posts/page/1'}>
              <div className="more">看更多</div>
            </Link>
          </div>
          <Category edges={data.allWordpressCategory.edges} />
        </div>

        <style jsx>{`
          .search-bar-up {
            position: relative;
            padding-top: 20px;
          }
          .more-btn-content {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .more-content {
            text-align: center;
          }

          .more {
            color: #ffffff;
            background-color: ${colors['dirt-brown']};
            font-size: 16px;
            border-radius: 50px;
            letter-spacing: 3px;
            padding: 15px 30px;
            display: inline-block;
            white-space: nowrap;
          }

          .more :hover {
            cursor: pointer;
            color: #ffffff;
            background-color: ${colors['dirt-brown-70%']};
          }

          @media (min-width: 579px) {
            .search-bar-up {
              padding-top: 60px;
            }
          }
        `}</style>
      </Layout>
    );
  }
}

export default Homepage;

export const pageQuery = graphql`
  query {
    allWordpressCategory {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    hotCategory: allWordpressCategory(
      sort: { fields: count, order: DESC }
      limit: 8
    ) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressPost(limit: 20, sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          slug
          categories {
            wordpress_id
            id
            name
            slug
          }
          tags {
            id
            name
            slug
          }
          jetpack_featured_media_url
        }
      }
    }
  }
`;
