import React from 'react';
import PropTypes from 'prop-types';
import Tag from './tag';
import SearchBar from './searchBar';

const BigSearchBar = props => {
  const { categories } = props;
  return (
    <div className="big-search-content">
      <div className="content-search">
        <SearchBar />
      </div>
      <div className="content-tag">
        {categories.map(edges => {
          const categoryProps = edges.node;
          return <Tag key={categoryProps.slug} {...categoryProps} />;
        })}
      </div>

      <style jsx>{`
        .big-search-content {
          position: absolute;
          top: -25px;
          left: calc(50% - 160px);
          width: 310px;
          min-height: 45px;
          background-color: #ffffff;
          margin: auto;
          padding: 5px;
          border-radius: 100px;
          -webkit-box-shadow: 0px 0px 17px -4px rgba(199, 199, 199, 0.69);
          -moz-box-shadow: 0px 0px 17px -4px rgba(199, 199, 199, 0.69);
          box-shadow: 0px 0px 17px -4px rgba(199, 199, 199, 0.69);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }

        .content-search {
          width: 100%;
        }

        .content-tag {
          display: none;
          width: 100%;
        }

        @media (min-width: 579px) {
          .big-search-content {
            top: -50px;
            left: calc(50% - 267.5px);
            width: 535px;
          }

          .content-search {
            max-width: 485px;
            margin-top: 10px;
          }

          .content-tag {
            display: unset;
            max-width: 485px;
            margin: 10px;
            display: flex;
            justify-content: flex-start;
            overflow: hidden;
          }
        }

        @media (min-width: 768px) {
        }

        @media (min-width: 924px) {
          .big-search-content {
            left: calc(50% - 400px);
            width: 800px;
          }

          .content-search {
            max-width: 750px;
          }

          .content-tag {
            max-width: 750px;
          }
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

BigSearchBar.propTypes = {
  categories: PropTypes.array,
};

BigSearchBar.defaultProps = {
  categories: [
    {
      slug: '家電',
      text: '家電',
    },
    {
      slug: '3C',
      text: '3C',
    },
    {
      slug: '3C',
      text: '3C',
    },
    {
      slug: '3C',
      text: '3C',
    },
    {
      slug: '3C',
      text: '3C',
    },
    {
      slug: '3C',
      text: '3C',
    },
    {
      slug: '3C',
      text: '3C',
    },
    {
      slug: '3C',
      text: '3C',
    },
    {
      slug: '3C',
      text: '3C',
    },
    {
      slug: '3C',
      text: '3C',
    },
    {
      slug: '3C',
      text: '3C',
    },
    {
      slug: '廚房用具',
      text: '廚房用具',
    },
  ],
};

export default BigSearchBar;
